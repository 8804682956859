import React, { ReactElement, useState } from 'react';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TPOFilterButton from '../TPOFilterButton/TPOFilterButton';

interface TPOTooltipProps {
  title: string;
  content: string | null;
  children: ReactElement;
}

const TPOTooltip = (props: TPOTooltipProps) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Box onClick={handleOpen} sx={{ cursor: 'pointer' }}>
        {props.children}
      </Box>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle
          sx={{
            color: 'primary.main',
            fontSize: '36px'
          }}
        >
          {props.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            sx={{
              fontSize: '16px',
              color: 'text.primary',
              whiteSpace: 'pre-line'
            }}
          >
            {props.content}
          </DialogContentText>
          <DialogActions>
            <TPOFilterButton text="Close" onClick={handleClose} selected={false} />
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default TPOTooltip;
