import { getToken } from '../../../auth/authenticator';
import { AuthState } from '../../../common/AuthContext.types';
import axiosClient from '../../axios-api';
import { InstitutionRating } from './InstitutionRating';

export async function getInstitutionRatings(
  userSession: AuthState
): Promise<InstitutionRating[]> {
  let finalResponse = [{"institutionName": ""} as InstitutionRating];

  let apiUrl: string = `/ratings?c_id=${userSession.clientId}`;

  await axiosClient({
    url: apiUrl,
    method: 'GET',
    headers: {
      Authorization: `Bearer ${await getToken()}`
    }
  })
    .then((response) => {
      finalResponse = response.data;
    })
    .catch((error) =>
      console.log('An error has occurred when fetching bank ratings - ', error?.message)
    );

  return finalResponse;
}
