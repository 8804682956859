import React, {useState, useEffect, useContext} from 'react';
import {Box, Tabs, Tab, Skeleton} from '@mui/material';
import GreyToGreyDiv from '../../common/grey_grey_div.png';
import GreyToWhiteDiv from '../../common/grey_white_div.png';
import AccountListSummary from './Sections/Summary/AccountListSummary';
import AccountsStatements from './Sections/AccountsStatements/AccountsStatements';
import AccountInvestments from './Sections/AccountInvestments/AccountInvestments';
import {AccountsStatement} from '../../networking/accounts/statements/AccountsStatement';
import {getAccountStatements} from '../../networking/accounts/statements/statements';
import {getAccountDetails} from '../../networking/accounts/details/details';
import {AccountsInvestment} from '../../networking/accounts/investments/AccountsInvestment.types';
import {getAccountInvestments} from '../../networking/accounts/investments/investments';
import {AccountDetails} from '../../networking/accounts/details/AccountDetails';
import {getInstitutionRatings} from '../../networking/accounts/ratings/ratings';
import {InstitutionRating} from '../../networking/accounts/ratings/InstitutionRating';

import {AuthContext} from '../../common/authContext';
import {hasContent} from '../../common/helpers';
import {StringFilterState, stringFilterToArray} from '../../components/StringFilter/StringFilter';
import {useTranslation} from 'react-i18next';
import {Link, useParams} from 'react-router-dom';

import {
    ACCOUNT_DETAILS_ACCOUNTS_PAGE,
    ACCOUNT_DETAILS_INVESTMENTS_PAGE
} from '../../routing/ROUTES';

const statementsFilterInitialState = {
    institution: {},
    productType: {},
    group: {},
    closedAccount: {'Closed accounts': {value: true}}
};

const investmentsFilterInitialState = {
    institution: {},
    productType: {},
    matureInvestment: {'Matured investments': {value: true}}
};

const initialDetails = {
    dateAsOf: '0',
    openAccounts: 0,
    totalBalance: 0,
    maturingInvestments: 0,
    maturingInvestmentsTotal: 0,
    balances: []
};

type PagePathParam = 'investments' | undefined;

const AccountDetailsPage = () => {
    const {authState} = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(true);
    const [isChartReloading, setIsChartReloading] = useState(false);
    const [investments, setInvestments] = useState<AccountsInvestment[] | {}>({});
    const [statements, setStatements] = useState<AccountsStatement[] | {}>({});
    const [details, setDetails] = useState<AccountDetails | {}>({});
    const [latestRatings, setLatestRatings] = useState<InstitutionRating[]>([{"institutionName": ""}]);
    const [accountRatings, setAccountRatings] = useState<InstitutionRating[]>([{"institutionName": ""}]);
    const [allRatings, setAllRatings] = useState<InstitutionRating[]>([{"institutionName": ""}]);
    const [statementsFilter, setStatementsFilter] = useState(statementsFilterInitialState);
    const [investmentsFilter, setInvestmentsFilter] = useState(investmentsFilterInitialState);
    const {t} = useTranslation();
    const {page} = useParams<{ page: PagePathParam }>();
    const tabState = page ?? 'accounts';

    const updateInvestmentsMatureInvestmentFilter = (newValues: any) => {
        setInvestmentsFilter((currentState) => {
            return {
                ...currentState,
                matureInvestment: {...newValues}
            };
        });
    };

    const updateInvestmentsInstitutionFilter = (newValues: StringFilterState) => {
        setInvestmentsFilter((currentState) => {
            return {
                ...currentState,
                institution: {...newValues}
            };
        });
    };

    const updateInvestmentsProductFilter = (newValues: StringFilterState) => {
        setInvestmentsFilter((currentState) => {
            return {
                ...currentState,
                productType: {...newValues}
            };
        });
    };

    const updateStatementsClosedAccountFilter = (newValues: any) => {
        setStatementsFilter((currentState) => {
            return {
                ...currentState,
                closedAccount: {...newValues}
            };
        });
    };

    const updateStatementsInstitutionFilter = (newValues: StringFilterState) => {
        setStatementsFilter((currentState) => {
            return {
                ...currentState,
                institution: {...newValues}
            };
        });
    };

    const updateStatementsProductFilter = (newValues: StringFilterState) => {
        setStatementsFilter((currentState) => {
            return {
                ...currentState,
                productType: {...newValues}
            };
        });
    };

    const updateStatementsGroupFilter = (newValues: StringFilterState) => {
        setStatementsFilter((currentState) => {
            return {
                ...currentState,
                group: {...newValues}
            };
        });
    };

    const setInitialStatementFilters = (statementResponse: AccountsStatement[]) => {
        let productTypeInitialState: StringFilterState = {};
        let institutionInitialState: StringFilterState = {};
        let groupInitialState: StringFilterState = {};

        Array.from(statementResponse as AccountsStatement[]).forEach((statement) => {
            productTypeInitialState = {
                ...productTypeInitialState,
                [statement.accountType]: {value: true}
            };
            institutionInitialState = {
                ...institutionInitialState,
                [statement.institutionName]: {value: true}
            };

            if (statement.accountGroups) {
                statement.accountGroups.forEach((group) => {
                    groupInitialState = {
                        ...groupInitialState,
                        [group.id]: {value: true, label: group.name}
                    };
                });
            }
        });

        setStatementsFilter((currentState) => {
            return {
                ...currentState,
                institution: {...institutionInitialState},
                productType: {...productTypeInitialState},
                group: {...groupInitialState}
            };
        });
    };

    const setInitialInvestmentsFilters = (statementResponse: AccountsInvestment[]) => {
        let productTypeInitialState: StringFilterState = {};
        let institutionInitialState: StringFilterState = {};

        Array.from(statementResponse as AccountsInvestment[]).forEach((statement) => {
            productTypeInitialState = {
                ...productTypeInitialState,
                [statement.accountType]: {value: true}
            };
            institutionInitialState = {
                ...institutionInitialState,
                [statement.institutionName]: {value: true}
            };
        });

        setInvestmentsFilter((currentState) => {
            return {
                ...currentState,
                institution: {...institutionInitialState},
                productType: {...productTypeInitialState}
            };
        });
    };

    useEffect(() => {
        const institutionFilterArray = stringFilterToArray(statementsFilter.institution);
        const productTypeFilterArray = stringFilterToArray(statementsFilter.productType);
        const groupFilterArray = stringFilterToArray(statementsFilter.group);

        if (institutionFilterArray.length === 0 || productTypeFilterArray.length === 0)
            return setDetails(initialDetails);

        setIsChartReloading(true);
        getAccountDetails(
            authState,
            institutionFilterArray,
            productTypeFilterArray,
            groupFilterArray,
            statementsFilter.closedAccount['Closed accounts'].value
        ).then((accountDetails) => {
            setDetails(accountDetails);
            getInstitutionRatings(authState).then(allRatings => {
                setAllRatings(allRatings);
                setLatestRatings(institutionFilterArray.map(bankName => {
                    let foundRating = allRatings.find((rating) => rating.institutionName == bankName);
                    if (foundRating) return foundRating;
                    else return {institutionName: bankName};
                }));
                setAccountRatings(allRatings.filter((rating) => institutionFilterArray.includes(rating.institutionName)));
            });
            setIsLoading(false);
            setIsChartReloading(false);
        });
    }, [authState, statementsFilter]);

    useEffect(() => {
        getAccountStatements(authState).then((accountStatements) => {
            setStatements(accountStatements);
            setInitialStatementFilters(accountStatements as AccountsStatement[]);
        });
        getAccountInvestments(authState).then((accountInvestment) => {
            setInvestments(accountInvestment);
            setInitialInvestmentsFilters(accountInvestment as AccountsInvestment[]);
        });
    }, [authState]);

    const {balances, ...summary} = details as AccountDetails;

    return (
        <Box
            sx={{
                backgroundColor: '#f2f2f2'
            }}
        >
            <Box>
                <img src={GreyToGreyDiv} width="1200px" alt="header-background1"/>
            </Box>

            {hasContent(details) && <AccountListSummary {...summary} />}

            <Box
                sx={{
                    backgroundColor: '#ffffff'
                }}
            >
                <img src={GreyToWhiteDiv} width="1200px" alt="header-background2"/>
            </Box>

            <Box
                sx={{
                    backgroundColor: '#ffffff',
                    borderBottom: '1px solid rgba(121, 121, 121, 0.2)',
                    color: 'text.primary',
                    display: 'flex',
                    justifyContent: 'center'
                }}
            >
                <Tabs value={tabState} indicatorColor="primary" textColor="inherit">
                    <Tab
                        label={t('accountDetailsPage.accountsListTab')}
                        sx={{
                            textTransform: 'none',
                            fontSize: '32px',
                            fontWeight: '400'
                        }}
                        value={'accounts'}
                        to={ACCOUNT_DETAILS_ACCOUNTS_PAGE}
                        component={Link}
                    />
                    <Tab
                        label={t('accountDetailsPage.investmentsTab')}
                        sx={{
                            textTransform: 'none',
                            fontSize: '32px',
                            fontWeight: '400'
                        }}
                        value={'investments'}
                        to={ACCOUNT_DETAILS_INVESTMENTS_PAGE}
                        component={Link}
                    />
                </Tabs>
            </Box>

            {isLoading ? (
                [...Array(10).keys()].map((item, i) => (
                    <Skeleton
                        animation="wave"
                        key={i}
                        height={50}
                        width="90%"
                        sx={{
                            margin: 'auto'
                        }}
                    />
                ))
            ) : (
                <>
                    {page === undefined && hasContent(statements) && hasContent(details) && (
                        <AccountsStatements
                            statements={statements as AccountsStatement[]}
                            balances={balances}
                            latestRatings={latestRatings as InstitutionRating[]}
                            accountRatings={accountRatings as InstitutionRating[]}
                            allRatings={allRatings as InstitutionRating[]}
                            dateAsOf={summary.dateAsOf}
                            institution={statementsFilter.institution}
                            setInstitution={updateStatementsInstitutionFilter}
                            productType={statementsFilter.productType}
                            setProductType={updateStatementsProductFilter}
                            group={statementsFilter.group}
                            setGroup={updateStatementsGroupFilter}
                            closedAccount={statementsFilter.closedAccount}
                            setClosedAccount={updateStatementsClosedAccountFilter}
                            reloading={isChartReloading}
                        />
                    )}

                    {page === 'investments' && (
                        <AccountInvestments
                            accountInvestments={investments as AccountsInvestment[]}
                            institution={investmentsFilter.institution}
                            productType={investmentsFilter.productType}
                            matureInvestment={investmentsFilter.matureInvestment}
                            setInstitution={updateInvestmentsInstitutionFilter}
                            setProductType={updateInvestmentsProductFilter}
                            setMatureInvestment={updateInvestmentsMatureInvestmentFilter}
                        />
                    )}
                </>
            )}
        </Box>
    );
};

export default AccountDetailsPage;
